var render = function () {
  var _vm$$slots;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-modal', "sygni-modal--".concat(_vm.size), !_vm.overflow ? 'sygni-modal--remove-overflow' : '', _vm.showCloseBtn ? 'sygni-modal--close-btn' : ''],
    attrs: {
      "id": _vm.testId
    }
  }, [_c('div', {
    staticClass: "sygni-modal__bg",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }), _c('div', {
    ref: "sygniModalBox",
    staticClass: "sygni-modal__box",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    class: ['sygni-modal__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _vm.showCloseBtn ? _c('div', {
    staticClass: "sygni-modal__head-actions"
  }, [_c('button', {
    staticClass: "sygni-modal__head-action sygni-modal__head-action--close",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Close")])]) : _vm._e(), _c('p', {
    staticClass: "sygni-modal__header"
  }, [_vm._v(_vm._s(_vm.header))]), _vm.description ? _c('p', {
    staticClass: "sygni-modal__desc"
  }, [_vm._v(_vm._s(_vm.description))]) : _vm._e(), _vm.hasContentSlot ? _c('div', {
    staticClass: "sygni-modal__content"
  }, [_vm._t("content")], 2) : _vm._e(), _vm.cancelText || _vm.confirmText || (_vm$$slots = _vm.$slots) !== null && _vm$$slots !== void 0 && _vm$$slots.additionalActions ? _c('div', {
    staticClass: "sygni-modal__actions"
  }, [_vm._t("additionalActions"), _vm.cancelText ? _c('sygni-rounded-button', {
    staticClass: "outline disabled",
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm._v(_vm._s(_vm.cancelText))]) : _vm._e(), _vm.confirmText ? _c('sygni-rounded-button', {
    staticClass: "filled black",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm._v(_vm._s(_vm.confirmText))]) : _vm._e()], 2) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }