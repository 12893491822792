



























































































































































































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import BookingModal from '@/modules/statements/components/BookingModal.vue'
import SygniLine from '@/components/layout/SygniLine.vue';
import { Product } from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import { CheckboxOption } from '@/store/types';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import { BTable } from 'bootstrap-vue';
import { Prop, Watch } from 'vue-property-decorator';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import { create, all } from 'mathjs'
import _ from 'lodash';

const math = create(all)

@Component({
  components: { SygniContainerTitle, SygniCheckbox, SygniArrowButton, SygniRectButton, SygniRoundedButton, UserPresentation, ExpandCollapse, SygniModal, SygniLinkButton, SygniLine, BookingModal, simplebar },
})
export default class StatementTransactionsTable extends SygniTable<Product> {
  @Prop({ default: null }) productObject: { objectId: string, objectType: string, data: any }
  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;
  isMatchingModalLoading: boolean = false;
  showCombineModal: boolean = false;
  bulkOptionsMarginTop: number = 0;
  isLoading: boolean = false;
  scrollbarRef = 'tableInner'
  matchingResults: any[] = []
  validationErrorModal: { show: boolean, message: string } = {
    show: false,
    message: null
  }
  refresh: any = null;
  
  tableFields = [
    {key: 'selected', sortable: false, label: '', class: ['selected']},
    {key: 'code', sortable: true, class: ['code', 'text-left']},
    {key: 'transferNumber', sortable: true, label: 'Transfer No.', class: ['transfer-number', 'text-left']},
    {key: 'transferDate', label: 'Date', sortable: true, class: ['transfer-date']},
    {key: 'amount', sortable: true, label: 'Value', class: ['amount', 'text-right']},
    {key: 'currency', sortable: true, class: ['currency', 'text-left']},
    {key: 'counterpartyName', sortable: true, label: 'Counterparty Name', class: ['counterparty-name', 'text-left'] },
    {key: 'counterpartyAccountNumber', sortable: true, label: 'CP. Account No.', class: ['account-number', 'text-left']},
    {key: 'description', sortable: true, class: ['description']},
    {key: 'status', sortable: true, class: ['status']},
    {key: 'actions', sortable: false, label: '', class: ['actions']},
  ]

  // ====================================
  // Synchronisation modal
  // ====================================

  showMatchingConfirmationModal: boolean = false;
  isBookingModalLoading: boolean = false;
  showBookingModal: boolean = false;
  bookingItems: any[] = []

  get transactionsTotal() {
    const transactionsTotal: number = _.cloneDeep(this.selectedItems?.filter((el: any) => el?.status?.toLowerCase() !== 'matched')).reduce((accumulator: any, object: any) => {
      return math.number(math.add(math.bignumber(accumulator), math.bignumber(object?.amount || 0)))
    }, 0)

    return transactionsTotal
  }

  get remainingValue() {
    const remaining: number = math.number(math.subtract(math.bignumber(this.productObject?.data?.value || 0), math.bignumber(this.productObject?.data?.paidValue || 0)))

    return remaining >= 0 ? remaining : 0
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get hasOnlyReconcilableDocuments() {
    const invalidItems = this.selectedItems?.filter((el: any) => {
      if (this.activeUserData?.role?.bankStatementRole === 'user') {
        return el?.status?.toUpperCase() === 'BOOK' || el?.status?.toUpperCase() === 'BOOKED' || el?.status?.toUpperCase() === 'MATCHED'
      }

      return false
    })

    return invalidItems?.length === 0 
  }

  get hasOnlyBookableTransactions() {
    if (this.activeUserData?.role?.bankStatementRole === 'user') return false

    const invalidItems = this.selectedItems?.filter((el: any) => {
      return !el?.investmentClientId || (el?.status?.toUpperCase() === 'BOOK' || el?.status?.toUpperCase() === 'BOOKED' )
    })

    return invalidItems?.length === 0
  }

  get isProcessing() {
    const items = this.items?.filter(item => (item.status as string)?.toLowerCase() == 'booking');

    return (items?.length) ? true : false;
  }

  async openBookingModal(items: string[] = []) {
    this.showBookingModal = true

    const investmentClientIds: string[] = items?.length ? items : _.uniq(this.selectedItems?.map((el: any) => el.investmentClientId));
    const filteredInvestmentClientIds: any[] = []

    for (const id of investmentClientIds) {
      const resp = await this.$store.dispatch('statements/checkInvestmentClientCounterpartyInfo', id)

      if (!(resp?.counterpartyId && resp?.code && resp?.externalId)) {
        const counterpartyObj = { investmentClientId: id, data: resp }
        filteredInvestmentClientIds.push(counterpartyObj)
      }
    }

    await (this.$refs.bookingModal as BookingModal).getCounterparties(filteredInvestmentClientIds)
  }

  async closeBookingModal() {
    this.$store.commit(this.setTableBusyMutation, true)
    this.showBookingModal = false
    await this.getItems(true)
  }

  async synchronise(item?: any) {
    if (item) {
      if (item?.investmentClientId && !(item?.status?.toUpperCase() === 'BOOK' || item?.status?.toUpperCase() === 'BOOKED')) {
        this.bookingItems = [item]
        this.openBookingModal([item?.investmentClientId])
      }
    } else {
      this.bookingItems = this.selectedItems
      this.openBookingModal()
    }
  }

  // ====================================
  // Component data
  // ====================================

  toggleAllRowsAction() {
    if (this.selectAllRowsBoolean === null) return;
    this.selectAllRowsBoolean = !this.selectAllRowsBoolean;

    if (this.selectAllRowsBoolean) {
      // const selectedRowEl = (this.$refs.statementTransactionsTable as BTable)?.$el?.querySelector('.table tbody tr:nth-of-type(1)');
      this.bulkOptionsMarginTop = 70;
      (this.$refs.statementTransactionsTable as BTable).selectAllRows();
    } else {
      (this.$refs.statementTransactionsTable as BTable).clearSelected();
    }

    this.bulkRows.forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  statusText(status: string) {
    if (status?.toUpperCase() === 'BOOK') {
      return 'BOOKED'
    }

    return this.$options.filters.snakeCaseToTitleCase(status)?.toUpperCase()
  }

  statusClass(status: string) {
    switch (status?.toLowerCase()) {
      case 'unmatched': 
        return 'danger';
      case 'matched': 
        return 'success';
      case 'booked':
      case 'book':
        return 'primary'
    }

    return 'black';
  }

  get successfulUploadedFilesLength(): number {
    const items = this.matchingResults;

    return items ? items?.length : 0;
  }

  get matchingDescription() {
    return `${this.successfulUploadedFilesLength}/${this.matchingResults?.length} records have been successfully processed`;
  }

  isDisabled(id: string) {
    const item: any = this.items?.find((item: any) => item.id === id)

    if (item?.status?.toLowerCase() === 'booked' || item?.status?.toLowerCase() === 'book' || (item && this.productObject?.data?.currency && item?.currency?.toLowerCase() !== this.productObject?.data?.currency?.toLowerCase())) {
      return true
    }

    return false
  }

  clearMatchingResults() {
    this.matchingResults = []
  }

  toggleTableRow(item: any, index: number) {
    // const selectedRowEl = (this.$refs.statementTransactionsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(${index + 1})`);
    this.bulkOptionsMarginTop = 70;
    if (this.bulkRows[index]) {
      this.bulkRows[index].value = !this.bulkRows[index]?.value;
    }

    if (item?.value) {
      (this.$refs.statementTransactionsTable as BTable).selectRow(index);
    } else {
      (this.$refs.statementTransactionsTable as BTable).unselectRow(index);
    }
  }

  rowClass(item: any) {
    const classNames: string[] = []

    if (this.rowsSelected?.find((el: any) => el.label === item?.id)) {
      classNames.push('row-selected')
    }

    if (this.productObject?.objectId === item?.matchedObjectId && this.productObject?.objectType === 'product') {
      classNames.push('row-matched')
    }

    return classNames
  }

  getTypeText(type: any): string {
    return type?.toUpperCase() === 'CLAT-TAX' ? 'CLAT TAX' : type?.toUpperCase()
  }

  getAccountNumber(accountNumber: string) {
    if (accountNumber) {
      return this.$options.filters.bankAccountNumber(accountNumber)
    }

    return 'NONE'
  }

  closeCombineModal() {
    this.showCombineModal = false
    this.isMatchingModalLoading = false
  }

  handleOpenModalAction() {
    if (this.productObject !== null) {
      this.showCombineModal = true
    }
  }

  get selectedItems(): any {
    const ids = this.rowsSelected.map((el: any) => el.label)

    return this.items?.filter((el: any) => ids.includes(el.id))
  }

  get rowsSelected() {
    const selectedRows = this.bulkRows.filter(el => el?.value);

    return selectedRows;
  }

  get selectedRowsLength() {
    const selectedRows = this.bulkRows.filter(el => el?.value);

    return selectedRows?.length;
  }

  selectRowEl(index: number) {
    if (this.$refs.statementTransactionsTable == undefined) return;

    this.deselectRowEl();
    const activeRow = ((this.$refs.statementTransactionsTable as StatementTransactionsTable).$el as HTMLElement).querySelector(`tbody tr[aria-rowindex="${index + 1}"]`);
    this.$scrollTo(activeRow);
    activeRow.classList.add('active');
  }

  deselectRowEl() {
    if (this.$refs.statementTransactionsTable == undefined) return;

    const rows = ((this.$refs.statementTransactionsTable as StatementTransactionsTable).$el as HTMLElement).querySelectorAll(`tbody tr`);
    rows.forEach(row => row.classList.remove('active'));
  }

  resetCheckboxes(keepExisting: boolean = false): void {
    if (!keepExisting) {
      this.bulkRows = [];
      this.items.forEach((el, index) => {
        this.$set(this.bulkRows, index, { label: el.id, value: false });
      });
    } else {
      this.items.forEach((el, index) => {
        // check if element exists
        if (!this.bulkRows[index]) {
          // add new element
          this.$set(this.bulkRows, index, { label: el.id, value: false })
        }
      })
    }
  }

  get filtersQuery() {
    let filtersQuery: string = this.$store.getters['statements/getStatementTransactionsTableFiltersQuery'];

    if (this.productObject?.objectId) {
      filtersQuery = `${filtersQuery}&matchedObjectId=${this.productObject?.objectId}`
    }

    return filtersQuery
  }

  getTooltipMessage(message: string, length: number = 35) {
    return message?.length <= length ? '' : message
  }

  goToProfile(investor: any): void {
    if(investor.investmentClientId) {
      this.$router.push({name: 'profile-dashboard-guest-investor', params: { id: investor.investmentClientId}})
    }
  }

  async getItems(keepExistingCheckboxes: boolean = false) {
    await this.$store.dispatch(this.$attrs.getItemsAction, this.filtersQuery);
    this.resetCheckboxes(keepExistingCheckboxes)
  }

  setQuery() {
    this.$store.commit('statements/setStatementTransactionsTableQuery', this.localTableQuery);
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('statements/setStatementTransactionsTableFiltersQuery', filtersQuery);
    this.resetItems()
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('statements/setStatementTransactionsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.resetItems()
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('statements/setStatementTransactionsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  openMatchingConfirmationModal() {
    this.showMatchingConfirmationModal = true
  }

  closeMatchingConfirmationModal() {
    this.showMatchingConfirmationModal = false
  }

  openValidationErrorModal(errorMessage: string) {
    this.$set(this.validationErrorModal, 'show', true)
    this.$set(this.validationErrorModal, 'message', errorMessage)
  }

  closeValidationErrorModal() {
    this.$set(this.validationErrorModal, 'show', false)
    this.$set(this.validationErrorModal, 'message', null)
  }

  async handleCombineModalAction() {
    if (this.remainingValue < this.transactionsTotal) {
      this.openValidationErrorModal(`Transactions can't be reconciled because their total value exceeds matching product value. <br><br>
      Remaining value: <span class="text-nowrap">${this.$options.filters.numberFormat(this.remainingValue, 2)} ${this.productObject?.data?.currency}</span><br>
      Transactions value: <span class="text-nowrap">${this.$options.filters.numberFormat(this.transactionsTotal, 2)} ${this.productObject?.data?.currency}</span>`)
      return
    }

    const unmatchTransactions = this.selectedItems?.filter((el: any) => el?.status?.toLowerCase() === 'matched')

    if (unmatchTransactions?.length) {
      this.openMatchingConfirmationModal()
    } else {
      await this.combineModalAction()
    }
  }

  async combineModalAction() {
    this.closeMatchingConfirmationModal()
    this.isMatchingModalLoading = true
    try {
      const matchTransactions = this.selectedItems?.filter((el: any) => el?.status?.toLowerCase() === 'unmatched')
      const unmatchTransactions = this.selectedItems?.filter((el: any) => el?.status?.toLowerCase() === 'matched')
      const { objectId, objectType } = this.productObject

      if (matchTransactions?.length || unmatchTransactions?.length) {
        const promises: any[] = []
    
        matchTransactions?.forEach((el: any) => {
          promises.push(this.$store.dispatch('statements/matchBankTransaction', { transactionId: el.id, objectId, objectType }))
        })
        
        unmatchTransactions?.forEach((el: any) => {
          promises.push(this.$store.dispatch('statements/unmatchBankTransaction', el.id))
        })
    
        const response = await Promise.allSettled(promises)
  
        const matchingResults = response?.map((el: any) => {
          const item = this.selectedItems?.find((item: any) => item.id == el?.value)
          const transferNumber = item?.transferNumber || ''
          return { transferNumber, status: el?.status === 'fulfilled' ? item?.status?.toLowerCase() === 'matched' ? 'Unmatched' : 'Matched' : 'Error', }
        })
  
        this.$emit('matched')
        this.closeCombineModal()
        this.matchingResults = matchingResults || []
        
      } else {
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: 'Nothing to match.'
        })
      }
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isMatchingModalLoading = false
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  async beforeMount() {
    await this.getItems()
    this.resetCheckboxes()
  }

  mounted() {
    this.onMounted();
  }

  getProductSummaryLink(productId: string) {
    return `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise/product/summary/${productId}/for-legal-entity`
  }

  async goToProductSummary(productId: string) {
    this.$store.commit('investors/clearAnnexingData');
    await this.$router.push({ path: this.getProductSummaryLink(productId) })
  }

  @Watch('selectAllRowsBoolean') onSelectAllRowsBooleanChange(): void {
    // const selectedRowEl = (this.$refs.statementTransactionsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(1)`);
    this.bulkOptionsMarginTop = 70;

    this.bulkRows.filter(el => el?.value !== undefined).forEach(row => {
      if (!this.isDisabled(row.label)) {
        row.value = this.selectAllRowsBoolean;
      }
    });
  }

  @Watch('items') onItemsChange(): void {
    if (this.refresh) {
      clearTimeout(this.refresh);
      this.refresh = undefined;
    }

    if (this.isProcessing) {
      this.$store.commit(this.setTableBusyMutation, true);
      this.refresh = setTimeout(() => {
        this.getItems();
      }, 3000);
    }
  }
}

