var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['statement-table', _vm.hideAdditionalColumns ? 'statement-table--simple' : '']
  }, [_c('div', {
    staticClass: "statement-table__inner"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedRowsLength > 0,
      expression: "selectedRowsLength > 0"
    }],
    staticClass: "bulk-options",
    style: "margin-top: ".concat(_vm.bulkOptionsMarginTop, "px;")
  }, [_c('sygni-loader', {
    attrs: {
      "is-loading": _vm.isStatusLoading
    }
  }), _c('div', {
    staticClass: "bulk-options__row"
  }, [_c('span', {
    staticClass: "bulk-options__label"
  }, [_vm._v("Selected: " + _vm._s(_vm.selectedRowsLength))]), _c('div', {
    staticClass: "bulk-options__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "bulk-options__action outline disabled",
    on: {
      "click": _vm.clearTableSelection
    }
  }, [_vm._v("Unselect all")]), _c('div', {
    class: ['dropdown', _vm.statementsDateOpened ? 'dropdown--opened' : '']
  }, [_c('div', {
    staticClass: "dropdown__placeholder"
  }, [_c('p', {
    staticClass: "dropdown__placeholder-text"
  }, [_vm._v("Actions")]), _c('div', {
    staticClass: "dropdown__placeholder-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": "Dropdown"
    }
  })])]), _c('div', {
    staticClass: "dropdown__inner"
  }, [!_vm.setInProgressEnabled && !_vm.setClosedEnabled ? _c('div', {
    staticClass: "dropdown__option dropdown__option--disabled"
  }, [_c('div', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("No actions available")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('X'),
      "alt": "Mark as In Progress"
    }
  })])]) : _vm._e(), _vm.setInProgressEnabled ? _c('div', {
    staticClass: "dropdown__option",
    on: {
      "click": function click($event) {
        return _vm.bulkSetStatus('in-progress');
      }
    }
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Mark as In Progress")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('Insights'),
      "alt": "Mark as In Progress"
    }
  })])]) : _vm._e(), _vm.setClosedEnabled ? _c('div', {
    staticClass: "dropdown__option",
    on: {
      "click": function click($event) {
        return _vm.bulkSetStatus('closed');
      }
    }
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Mark as Approved")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('APPROVE'),
      "alt": "Mark as Approved"
    }
  })])]) : _vm._e()])])], 1)])], 1), _c('b-table', _vm._g(_vm._b({
    ref: "statementsTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy,
      "show-empty": true
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "row-clicked": _vm.onRowClicked,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "head(selected)",
      fn: function fn() {
        return [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.selectAllRowsBoolean,
            callback: function callback($$v) {
              _vm.selectAllRowsBoolean = $$v;
            },
            expression: "selectAllRowsBoolean"
          }
        })];
      },
      proxy: true
    }, _vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(selected)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table-checkbox",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.toggleTableRow(_vm.bulkRows[rowData.index], rowData.index);
            }
          }
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.bulkRows[rowData.index].value,
            callback: function callback($$v) {
              _vm.$set(_vm.bulkRows[rowData.index], "value", $$v);
            },
            expression: "bulkRows[rowData.index].value"
          }
        })], 1)];
      }
    }, {
      key: "cell(fullNumber)",
      fn: function fn(rowData) {
        return [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              delay: 0,
              'no-fade': true,
              boundary: 'viewport'
            },
            expression: "{ delay: 0, 'no-fade': true, boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap",
          attrs: {
            "title": rowData.item.fullNumber
          }
        }, [_vm._v(_vm._s(_vm._f("trimString")(rowData.item.fullNumber, 30)))])];
      }
    }, {
      key: "cell(accountNumber)",
      fn: function fn(rowData) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("bankAccountNumber")(rowData.item.accountNumber)))])];
      }
    }, {
      key: "cell(issueDate)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.issueDate)))])];
      }
    }, {
      key: "cell(closeDate)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.closeDate)))])];
      }
    }, {
      key: "cell(currency)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm.getCurrencyLabel(rowData.item.currency)))])];
      }
    }, {
      key: "cell(balance)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table-price-cell"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.math.number(_vm.math.bignumber(Number(rowData.item.balance).toFixed(2))), 2)))])])];
      }
    }, {
      key: "cell(movements)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table-price-cell"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.math.number(_vm.math.bignumber(Number(rowData.item.movements).toFixed(2))), 2)))])])];
      }
    }, {
      key: "cell(revenue)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table-price-cell"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.math.number(_vm.math.bignumber(Number(rowData.item.revenue).toFixed(2))), 2)))])])];
      }
    }, {
      key: "cell(expenditure)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table-price-cell"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.math.number(_vm.math.bignumber(Number(rowData.item.expenditure).toFixed(2))), 2)))])])];
      }
    }, {
      key: "cell(closingBalance)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table-price-cell"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.math.number(_vm.math.bignumber(Number(rowData.item.closingBalance).toFixed(2))), 2)))])])];
      }
    }, {
      key: "cell(systemBalance)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table-price-cell"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.math.number(_vm.math.bignumber(Number(rowData.item.systemBalance).toFixed(2))), 2)))])])];
      }
    }, {
      key: "cell(systemMovements)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table-price-cell"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.math.number(_vm.math.bignumber(Number(rowData.item.systemMovements).toFixed(2))), 2)))])])];
      }
    }, {
      key: "cell(systemRevenue)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table-price-cell"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.math.number(_vm.math.bignumber(Number(rowData.item.systemRevenue).toFixed(2))), 2)))])])];
      }
    }, {
      key: "cell(systemExpenditure)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table-price-cell"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.math.number(_vm.math.bignumber(Number(rowData.item.systemExpenditure).toFixed(2))), 2)))])])];
      }
    }, {
      key: "cell(systemClosingBalance)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table-price-cell"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.math.number(_vm.math.bignumber(Number(rowData.item.systemClosingBalance).toFixed(2))), 2)))])])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: ['outline round', _vm.statusClassName(rowData.item.status)],
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(_vm._s(_vm.statusText(rowData.item.status)))])], 1)];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "td__actions td__actions--simple",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, _vm._l(_vm.allActions, function (action) {
          return _c('div', {
            key: action,
            staticClass: "action"
          }, [_vm.hasAction(action, rowData.item) ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: {
                delay: 0,
                'no-fade': true,
                boundary: 'viewport'
              },
              expression: "{ delay: 0, 'no-fade': true, boundary: 'viewport' }",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "action__el",
            attrs: {
              "title": _vm.getActionMessage(action)
            },
            on: {
              "click": function click($event) {
                return _vm.handleRowAction(action, rowData.item.id);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": _vm.getActionIcon(action)
            }
          })]) : _vm._e()]);
        }), 0)];
      }
    }, {
      key: "custom-foot",
      fn: function fn(data) {
        return [_c('tr', _vm._l(data.columns, function (i) {
          var _data$fields, _data$fields2, _data$fields3, _data$fields4, _data$fields5, _data$fields6, _data$fields7, _data$fields8, _data$fields9, _data$fields10, _data$fields11, _data$fields12, _data$fields13, _data$fields14, _data$fields15;

          return _c('td', {
            key: i,
            class: "td-".concat(data.fields[i - 1].key, " ").concat(((_data$fields = data.fields[i - 1]) === null || _data$fields === void 0 ? void 0 : _data$fields.class) || '')
          }, [((_data$fields2 = data.fields[i - 1]) === null || _data$fields2 === void 0 ? void 0 : _data$fields2.key) == 'fullNumber' ? _c('div', [_c('p', [_vm._v("Total invoices:")])]) : _vm._e(), ((_data$fields3 = data.fields[i - 1]) === null || _data$fields3 === void 0 ? void 0 : _data$fields3.key) == 'accountNumber' ? _c('div', [_c('p', [_vm._v(_vm._s(_vm.items.length ? _vm.items.length : 0))])]) : _vm._e(), ((_data$fields4 = data.fields[i - 1]) === null || _data$fields4 === void 0 ? void 0 : _data$fields4.key) == 'closeDate' ? _c('div', [_c('p', [_vm._v("Total amount:")])]) : _vm._e(), ((_data$fields5 = data.fields[i - 1]) === null || _data$fields5 === void 0 ? void 0 : _data$fields5.key) == 'balance' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.balanceTotals), function (_ref) {
            var key = _ref[0],
                value = _ref[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields6 = data.fields[i - 1]) === null || _data$fields6 === void 0 ? void 0 : _data$fields6.key) == 'movements' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.movementTotals), function (_ref2) {
            var key = _ref2[0],
                value = _ref2[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields7 = data.fields[i - 1]) === null || _data$fields7 === void 0 ? void 0 : _data$fields7.key) == 'revenue' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.revenueTotals), function (_ref3) {
            var key = _ref3[0],
                value = _ref3[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields8 = data.fields[i - 1]) === null || _data$fields8 === void 0 ? void 0 : _data$fields8.key) == 'expenditure' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.expenditureTotals), function (_ref4) {
            var key = _ref4[0],
                value = _ref4[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields9 = data.fields[i - 1]) === null || _data$fields9 === void 0 ? void 0 : _data$fields9.key) == 'closingBalance' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.closingBalanceTotals), function (_ref5) {
            var key = _ref5[0],
                value = _ref5[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields10 = data.fields[i - 1]) === null || _data$fields10 === void 0 ? void 0 : _data$fields10.key) == 'systemBalance' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.systemBalanceTotals), function (_ref6) {
            var key = _ref6[0],
                value = _ref6[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields11 = data.fields[i - 1]) === null || _data$fields11 === void 0 ? void 0 : _data$fields11.key) == 'systemMovements' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.systemMovementTotals), function (_ref7) {
            var key = _ref7[0],
                value = _ref7[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields12 = data.fields[i - 1]) === null || _data$fields12 === void 0 ? void 0 : _data$fields12.key) == 'systemRevenue' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.systemRevenueTotals), function (_ref8) {
            var key = _ref8[0],
                value = _ref8[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields13 = data.fields[i - 1]) === null || _data$fields13 === void 0 ? void 0 : _data$fields13.key) == 'systemExpenditure' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.systemExpenditureTotals), function (_ref9) {
            var key = _ref9[0],
                value = _ref9[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields14 = data.fields[i - 1]) === null || _data$fields14 === void 0 ? void 0 : _data$fields14.key) == 'systemClosingBalance' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.systemClosingBalanceTotals), function (_ref10) {
            var key = _ref10[0],
                value = _ref10[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields15 = data.fields[i - 1]) === null || _data$fields15 === void 0 ? void 0 : _data$fields15.key) == 'currency' ? _c('div', _vm._l(Object.entries(_vm.balanceTotals), function (_ref11) {
            var key = _ref11[0];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(key))]);
          }), 0) : _vm._e()]);
        }), 0)];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center text-danger spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }