





























































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import { Product} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import { CheckboxOption } from '@/store/types';
import SygniRadio from '@/components/inputs/SygniRadio.vue';
import { BTable } from 'bootstrap-vue';
import { Watch } from 'vue-property-decorator';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

@Component({
  components: { SygniContainerTitle, SygniRadio, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, simplebar }
})
export default class StatementProductsTable extends SygniTable<Product> {
  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;
  bulkOptionsMarginTop: number = 0;
  selectedProductId: string = null;
  
  tableFields = [
    {key: 'selected', sortable: false, label: '', class: ['selected']},
    {key: 'code', sortable: true, class: ['code', 'text-left']},
    {key: 'transferNumber', sortable: true, label: 'Transfer No.', class: ['transfer-number', 'text-left']},
    {key: 'date', label: 'Date', sortable: true, class: ['agreement-date']},
    {key: 'value', sortable: true, class: ['value', 'text-right']},
    {key: 'currency', sortable: true, class: ['currency', 'text-left']},
    {key: 'investorName', sortable: true, label: 'Investor Name', class: ['investor-name', 'text-left'] },
    {key: 'accountNumber', sortable: true, label: 'Bank Account', class: ['int-pmt-account-number', 'text-left']},
    {key: 'type', sortable: true, class: ['type']},
    {key: 'paymentStatus', sortable: true, class: ['payment-status']},
    // {key: 'actions', sortable: false, class: ['actions']},
  ]
  
  scrollbarRef = 'tableInner';

  toggleAllRowsAction() {
    if (this.selectAllRowsBoolean === null) return;
    this.selectAllRowsBoolean = !this.selectAllRowsBoolean;

    if (this.selectAllRowsBoolean) {
      const selectedRowEl = (this.$refs.statementProductsTable as BTable)?.$el?.querySelector('.table tbody tr:nth-of-type(1)');
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
      (this.$refs.statementProductsTable as BTable).selectAllRows();
    } else {
      (this.$refs.statementProductsTable as BTable).clearSelected();
    }

    this.bulkRows.forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  rowClass(item: any) {
    return this.selectedProductId === `${item?.id}-||-${item?.type}` ? 'row-selected' : ''
  }

  get rowsSelected() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows;
  }

  get selectedRowsLength() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows?.length;
  }

  selectRowEl(index: number) {
    if (this.$refs.statementProductsTable == undefined) return;

    this.deselectRowEl();
    const activeRow = ((this.$refs.statementProductsTable as StatementProductsTable).$el as HTMLElement).querySelector(`tbody tr[aria-rowindex="${index + 1}"]`);
    this.$scrollTo(activeRow);
    activeRow.classList.add('active');
  }

  deselectRowEl() {
    if (this.$refs.statementProductsTable == undefined) return;

    const rows = ((this.$refs.statementProductsTable as StatementProductsTable).$el as HTMLElement).querySelectorAll(`tbody tr`);
    rows.forEach(row => row.classList.remove('active'));
  }

  resetCheckboxes(): void {
    this.bulkRows = [];
    this.items.forEach((el, index) => {
      this.$set(this.bulkRows, index, { label: `${el.id}-||-${el.type}`, value: false });
    });
  }

  get filtersQuery() {
    return this.$store.getters['statements/getStatementProductsTableFiltersQuery'];
  }

  getTooltipMessage(message: string, length: number = 35) {
    return message?.length <= length ? '' : message
  }

  goToProfile(investor: any): void {
    if(investor.investmentClientId) {
      this.$router.push({name: 'profile-dashboard-guest-investor', params: { id: investor.investmentClientId}})
    }
  }

  async getItems() {
    await this.$store.dispatch(this.$attrs.getItemsAction, this.filtersQuery);
    this.selectedProductId = null
    this.resetCheckboxes()
  }

  setQuery() {
    this.$store.commit('statements/setStatementProductsTableQuery', this.localTableQuery);
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('statements/setStatementProductsTableFiltersQuery', filtersQuery);
    this.resetItems()
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('statements/setStatementProductsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.resetItems()
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('statements/setStatementProductsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  paymentStatusClass(paymentStatus: any) {
    switch (paymentStatus?.toLowerCase()) {
      case ('paid'): {
        return 'primary';
      }
      case ('partially-paid'): {
        return 'success';
      }
      case ('unpaid'): {
        return 'danger';
      }
    }
    return '';
  }

  paymentStatusText(paymentStatus: any): string {

    return this.$options.filters.snakeCaseToTitleCase(paymentStatus)?.toUpperCase()
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  async beforeMount() {
    await this.getItems()
    this.resetCheckboxes()
  }

  mounted () {
    this.onMounted();
  }

  getProductSummaryLink(productId: string) {
      return `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise/product/summary/${productId}/for-legal-entity`
  }

  async goToProductSummary(productId: string) {
    this.$store.commit('investors/clearAnnexingData');
    await this.$router.push({ path: this.getProductSummaryLink(productId) })
  }

  @Watch('selectAllRowsBoolean') onSelectAllRowsBooleanChange(): void {
    const selectedRowEl = (this.$refs.statementProductsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(1)`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;

    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  @Watch('selectedProductId') onSelectedProductIdChange() {
    if (this.selectedProductId) {
      const objectData = this.selectedProductId?.split('-||-')
      if (objectData?.length === 2) {
        const data: any = this.items.find((el: any) => el.id === objectData[0] && el.type === objectData[1])
        this.$emit('productSelected', { objectId: objectData[0], objectType: objectData[1], data })
      } else {
        this.selectedProductId = null
        this.resetCheckboxes()
      }
    } else {
      this.$emit('productSelected', null)
    }
  }
}

