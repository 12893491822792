var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "booking-modal"
  }, [_c('sygni-modal', {
    ref: "sygniModal",
    attrs: {
      "header": _vm.modalHeader,
      "description": _vm.modalDescription,
      "isLoading": _vm.isLoading,
      "size": "large",
      "disabled": !_vm.isStepValidated,
      "cancelText": _vm.cancelText,
      "confirmText": _vm.confirmText
    },
    on: {
      "close": _vm.handleCloseAction,
      "cancel": _vm.handleCancelAction,
      "confirm": _vm.handleConfirmAction
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$counterpartiesDat, _vm$counterpartiesDat2;

        return [_vm.viewType === 'synchronise' ? [_c('div', {
          staticClass: "booking-modal__form"
        }, [!_vm.hasError ? [_c('p', {
          staticClass: "description mb-5"
        }, [_vm._v("In order to book transaction you'll need to synchronise missing " + _vm._s(_vm.investorsAmount === 1 ? 'investor' : 'investors') + " first.")]), _c('div', {
          key: _vm.reRender,
          staticClass: "booking-modal__select mb-5"
        }, [_c('div', {
          staticClass: "booking-modal__select-col"
        }, [_c('sygni-radio', {
          staticClass: "secondary mb-3",
          attrs: {
            "radioValue": false,
            "group": _vm.currentInvestorId,
            "disabled": _vm.isDisabled,
            "label": "Add new counterparty"
          },
          on: {
            "input": function input($event) {
              return _vm.selectRadioBtn(_vm.currentInvestorId, false);
            }
          },
          model: {
            value: _vm.radioBtnValues[_vm.currentInvestorId],
            callback: function callback($$v) {
              _vm.$set(_vm.radioBtnValues, _vm.currentInvestorId, $$v);
            },
            expression: "radioBtnValues[currentInvestorId]"
          }
        })], 1), _c('div', {
          staticClass: "booking-modal__select-col"
        }, [_c('sygni-radio', {
          staticClass: "secondary mb-3",
          attrs: {
            "radioValue": true,
            "group": _vm.currentInvestorId,
            "disabled": _vm.isDisabled || ((_vm$counterpartiesDat = _vm.counterpartiesData[_vm.currentInvestorId]) === null || _vm$counterpartiesDat === void 0 ? void 0 : _vm$counterpartiesDat.length) === 0,
            "label": "Select existing counterparty"
          },
          on: {
            "input": function input($event) {
              return _vm.selectRadioBtn(_vm.currentInvestorId, true);
            }
          },
          model: {
            value: _vm.radioBtnValues[_vm.currentInvestorId],
            callback: function callback($$v) {
              _vm.$set(_vm.radioBtnValues, _vm.currentInvestorId, $$v);
            },
            expression: "radioBtnValues[currentInvestorId]"
          }
        }), _c('sygni-select', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.radioBtnValues[_vm.currentInvestorId],
            expression: "radioBtnValues[currentInvestorId]"
          }],
          staticClass: "mb-3",
          attrs: {
            "allow-empty": false,
            "disabled": _vm.isDisabled || ((_vm$counterpartiesDat2 = _vm.counterpartiesData[_vm.currentInvestorId]) === null || _vm$counterpartiesDat2 === void 0 ? void 0 : _vm$counterpartiesDat2.length) === 0,
            "label": "",
            "options": _vm.counterpartiesData[_vm.currentInvestorId] || []
          },
          model: {
            value: _vm.counterpartiesValues[_vm.currentInvestorId],
            callback: function callback($$v) {
              _vm.$set(_vm.counterpartiesValues, _vm.currentInvestorId, $$v);
            },
            expression: "counterpartiesValues[currentInvestorId]"
          }
        })], 1)])] : [_c('p', {
          staticClass: "description mb-5 text-danger"
        }, [_vm._v("There was a problem. Investor not yet synchronised with Optima.")])], _c('p', {
          staticClass: "title mb-3 text-left"
        }, [_vm._v("List of investor transactions")]), _vm._l(_vm.filteredSelectedItems, function (item, index) {
          return _c('div', {
            key: item.id,
            staticClass: "booking-modal-item"
          }, [_c('div', {
            staticClass: "booking-modal-item__header mb-3"
          }, [_vm._v("Transaction " + _vm._s(index + 1))]), _c('div', {
            staticClass: "booking-modal-item__details mb-3"
          }, [_c('p', [_vm._v("Code: " + _vm._s((item === null || item === void 0 ? void 0 : item.code) || 'NONE'))]), _c('p', [_vm._v("Transfer Number: " + _vm._s((item === null || item === void 0 ? void 0 : item.transferNumber) || ''))]), _c('p', [_vm._v("Counterparty Name: " + _vm._s(_vm.useName ? item === null || item === void 0 ? void 0 : item.name : (item === null || item === void 0 ? void 0 : item.counterpartyName) || ''))]), _c('p', [_vm._v("Value: " + _vm._s(_vm._f("numberFormat")(item === null || item === void 0 ? void 0 : item.amount, 2)) + " " + _vm._s((item === null || item === void 0 ? void 0 : item.currency) || ''))])])]);
        })], 2)] : _vm._e(), _vm.viewType === 'generateOppositeAccounts' || _vm.viewType === 'summary' || _vm.viewType === 'result' ? [_c('sygni-date-picker', {
          attrs: {
            "clearable": false,
            "type": "year",
            "disabled": _vm.isOppositeAccountDisabled,
            "placeholder": "YYYY",
            "format": "YYYY",
            "label": "ACCOUNTING DATE"
          },
          model: {
            value: _vm.accountingDate,
            callback: function callback($$v) {
              _vm.accountingDate = $$v;
            },
            expression: "accountingDate"
          }
        }), _vm._l(_vm.selectedItems, function (item, index) {
          var _vm$bookingResults$it, _vm$bookingResults$it2, _vm$bookingResults$it3, _vm$bookingResults$it4;

          return _c('div', {
            key: item.id,
            staticClass: "booking-modal-item"
          }, [_c('div', {
            staticClass: "booking-modal-item__header mb-3"
          }, [_vm._v("Transaction " + _vm._s(index + 1))]), _c('div', {
            staticClass: "booking-modal-item__details mb-3"
          }, [_c('p', [_vm._v("Code: " + _vm._s((item === null || item === void 0 ? void 0 : item.code) || 'NONE'))]), _c('p', [_vm._v("Transfer Number: " + _vm._s((item === null || item === void 0 ? void 0 : item.transferNumber) || ''))]), _c('p', [_vm._v("Counterparty Name: " + _vm._s(_vm.useName ? item === null || item === void 0 ? void 0 : item.name : (item === null || item === void 0 ? void 0 : item.counterpartyName) || ''))]), _c('p', [_vm._v("Value: " + _vm._s(_vm._f("numberFormat")(item === null || item === void 0 ? void 0 : item.amount, 2)) + " " + _vm._s((item === null || item === void 0 ? void 0 : item.currency) || ''))])]), _vm.showOppositeAccountField ? _c('div', {
            staticClass: "booking-modal-item__option"
          }, [item !== null && item !== void 0 && item.suggestedOppositeAccount && !_vm.isOppositeAccountDisabled ? _c('p', {
            staticClass: "description mb-1"
          }, [_vm._v("Suggested: "), _c('sygni-link-button', {
            staticClass: "red",
            attrs: {
              "tooltip": !_vm.isOppositeAccountDisabled ? 'Click to apply suggestion' : '',
              "type": "simple"
            },
            on: {
              "click": function click($event) {
                return _vm.setOppositeAccount(item === null || item === void 0 ? void 0 : item.suggestedOppositeAccount, item, "oppositeAccount".concat(index));
              }
            }
          }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.suggestedOppositeAccount))])], 1) : _vm._e(), _c('div', {
            staticClass: "booking-modal-item__select"
          }, [_c('sygni-input', {
            ref: "oppositeAccount".concat(index),
            refInFor: true,
            staticClass: "mb-3",
            attrs: {
              "placeholder": "ENTER OPPOSITE ACCOUNT",
              "disabled": _vm.isOppositeAccountDisabled,
              "value": item === null || item === void 0 ? void 0 : item.oppositeAccount
            },
            on: {
              "keyup": function keyup($event) {
                var _$event$target;

                return _vm.onDebouncedCheckOppositeAccount(item, $event === null || $event === void 0 ? void 0 : (_$event$target = $event.target) === null || _$event$target === void 0 ? void 0 : _$event$target.value);
              },
              "input": function input($event) {
                return _vm.setOppositeAccount($event, item);
              }
            }
          }), item !== null && item !== void 0 && item.oppositeAccount ? _c('button', {
            class: ['booking-modal-item__btn', item !== null && item !== void 0 && item.oppositeAccountExists ? 'valid' : '']
          }, [!(item !== null && item !== void 0 && item.oppositeAccountExists) ? [item !== null && item !== void 0 && item.isLoading ? _c('b-spinner', {
            staticClass: "black"
          }) : _vm._e()] : [!(item !== null && item !== void 0 && item.isLoading) ? _c('div', {
            staticClass: "success-box"
          }, [_c('svg', {
            attrs: {
              "id": "Component_24_260",
              "data-name": "Component 24 – 260",
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "16",
              "height": "16",
              "viewBox": "0 0 16 16"
            }
          }, [_c('path', {
            attrs: {
              "id": "Path_1170",
              "data-name": "Path 1170",
              "d": "M4.084,8.372h0l3.3,3.3,5.264-5.264",
              "transform": "translate(-0.367 -1.044)",
              "fill": "none",
              "stroke": "#fff",
              "stroke-width": "1.5"
            }
          })])]) : _c('b-spinner')]], 2) : _vm._e()], 1), !(item !== null && item !== void 0 && item.oppositeAccountExists) ? _c('p', {
            staticClass: "description"
          }, [_vm._v("Opposite account not exists in Optima. "), _c('sygni-link-button', {
            staticClass: "red",
            attrs: {
              "type": "simple"
            },
            on: {
              "click": function click($event) {
                return _vm.addOppositeAccount(item);
              }
            }
          }, [_vm._v("Click to add it.")])], 1) : _vm._e()]) : _vm._e(), _vm.viewType === 'result' ? _c('div', {
            key: _vm.reRender,
            staticClass: "booking-modal-item__option booking-modal-item__option--right"
          }, [((_vm$bookingResults$it = _vm.bookingResults[item.id]) === null || _vm$bookingResults$it === void 0 ? void 0 : _vm$bookingResults$it.status) === 'fulfilled' ? [!((_vm$bookingResults$it2 = _vm.bookingResults[item.id]) !== null && _vm$bookingResults$it2 !== void 0 && _vm$bookingResults$it2.isLoading) ? [_c('p', {
            staticClass: "booking-modal-item__notification description text-right text-success"
          }, [_vm._v("Transaction booked successfully.")])] : [_c('b-spinner')]] : _vm._e(), ((_vm$bookingResults$it3 = _vm.bookingResults[item.id]) === null || _vm$bookingResults$it3 === void 0 ? void 0 : _vm$bookingResults$it3.status) === 'rejected' ? [!((_vm$bookingResults$it4 = _vm.bookingResults[item.id]) !== null && _vm$bookingResults$it4 !== void 0 && _vm$bookingResults$it4.isLoading) ? [_c('p', {
            staticClass: "booking-modal-item__notification description text-right text-danger"
          }, [_vm._v("Something went wrong.")]), _c('sygni-link-button', {
            staticClass: "booking-modal-item__btn text-black text-right mt-3",
            attrs: {
              "type": "simple"
            },
            on: {
              "click": function click($event) {
                return _vm.bookTransaction(_vm.bookingResults[item.id], item.id);
              }
            }
          }, [_vm._v("Try again")])] : [_c('b-spinner')]] : _vm._e()], 2) : _vm._e()]);
        })] : _vm._e()];
      },
      proxy: true
    }, {
      key: "additionalActions",
      fn: function fn() {
        return [_vm.viewType === 'generateOppositeAccounts' && !_vm.isStepValidated ? _c('div', {
          staticClass: "additional-actions mb-4"
        }, [_c('p', {
          staticClass: "description"
        }, [_vm._v("You can't go to summary because not all opposite accounts exists. "), _c('sygni-link-button', {
          staticClass: "filled red",
          attrs: {
            "type": "simple"
          },
          on: {
            "click": _vm.addAllOppositeAccounts
          }
        }, [_vm._v("Click here to add them")])], 1)]) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }