import { render, staticRenderFns } from "./StatementTransactionsTable.vue?vue&type=template&id=0bd3aa20&scoped=true&"
import script from "./StatementTransactionsTable.vue?vue&type=script&lang=ts&"
export * from "./StatementTransactionsTable.vue?vue&type=script&lang=ts&"
import style0 from "./StatementTransactionsTable.vue?vue&type=style&index=0&id=0bd3aa20&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd3aa20",
  null
  
)

export default component.exports